@import '../../submodules/src/styles/mixins';
@import '_variables';
@import 'element/horizontal-list';
@import 'element/_accordion';

h2 {
    margin-top: 1.6rem;
    @media (@min-screen-sm) {
        margin-top: 3rem;
    }
}

.box-desc {
    padding: 0.3rem 1rem;
    font-family: @roboto;
    top: 88%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;

    @media (@max-screen-sm) {
        font-size: 0.75em;
        top: 80%;
    }
}

.fix-iOS {
    @supports (-webkit-touch-callout: none) {
        position: relative !important;
        width: fit-content;
        bottom: 50px;
    }
}

.small-icon {
    flex-basis: 1rem;
    width: 1rem;
    height: 1rem;
}

.home-top-banners {
    .home-banner {
        @media (@max-screen-sm) {
            margin-bottom: 2rem;
        }
    }

    @media (@max-screen-md) {
        margin-top: 0 !important;
    }
}

.home-banner-wrapper {
    @media (@max-screen-xs) {
        padding: 0 !important;
    }
}

.home-newsletter-wrapper {
    @media (@max-screen-sm) {
        > :first-child {
            flex: 0 0 100%;
        }
    }
}

.gap {
    @media (@max-screen-sm) {
        gap: 2rem;
    }
}

.home-purpose h3 {
    @media (@max-screen-md) {
        text-align: center;
    }
}

.home-post-read-more {
    @media (@max-screen-md) {
        text-align: right;
    }
}

.home-collections-mini-banners {
    > * {
        width: 100%;
    }

    @media (@min-screen-sm) {
        > * {
            max-width: calc(25% - 2rem);
        }
    }
    @media (@min-screen-md) {
        > * {
            max-width: calc(20% - 2rem);
        }
    }
}

.home-categories svg {
    fill: white;
}

.home-categories-item {
    background-color: @color-default-1;
    transition: background-color @transition-default;

    &:hover {
        text-decoration: none;
        background-color: @color-primary-1;
    }
}

.home-categories {
    flex-wrap: nowrap;
    place-content: space-between;
}

.home-categories > * {
    max-width: 100%;
    @media (@min-screen-md) {
        max-width: calc(25% - 2rem);
    }
}

.home-category-box {
    box-sizing: border-box;
    background-color: #d9d9d947;
}

.home-insta-post-link {
    background-color: @color-white;
    transition: background-color @transition-default;

    &:hover {
        text-decoration: none;
        background-color: @color-default-2;
    }
}

.home-insta-product-link {
    color: @color-white;
    transition: color @transition-default;

    &:hover {
        text-decoration: none;
        color: @color-primary-1;
    }
}

.home-flex-grid {
    .grid-h-spacing(1rem);
    .grid-v-spacing(1rem);
}

.home-welcome-text {
    font-size: 1.638rem;
    padding: 1.5rem 0 0.5rem;
    margin: 0;
    font-family: @roboto;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0.02em;
    color: #1e1e1d;
    @media (@max-screen-lg) {
        font-size: 1.35em !important;
    }
    @media (@max-screen-md) {
        font-size: 1em !important;
        padding: 0.5rem 0 0 0 !important;
    }
}

.home-about-us {
    font-size: 1em;
    font-family: @roboto;
    font-weight: 400;
    line-height: 2em;
    letter-spacing: 0.02em;
    color: #1e1e1d;
    text-decoration: underline;
    padding: 1.7rem 0 0.5rem 1rem;
    @media (@max-screen-md) {
        padding: 0.5rem 0 0 0rem !important;
    }
}

.home-sections {
    .product-list-item {
        .product-list-item-title {
            font-size: 1.1rem;
        }
    }

    .product-list-item:first-child {
        .edit-iOS {
            padding-left: 0.5rem;
        }
    }
}

.home-exchange-banner {
    width: 100%;
    @media (@max-screen-sm) {
        margin-top: 1rem;
    }

    /*    @media (@min-screen-sm) {
          width: 100%;
          height: 150px;
      }*/
}

.home-subtitle {
    font-family: @roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 1.125em;
    line-height: 1.3125em;
    letter-spacing: 0.02em;
    color: #1e1e1d;

    @media (@min-screen-sm) {
        font-size: 1.4375em;
    }
}

.home-chicundum-textbox {
    background: #ffffff;
    box-sizing: border-box;
    padding: 0 1rem;
    position: absolute;
    bottom: 5rem;
    left: 10rem;
    font-family: @roboto;
    overflow: hidden;
    display: flex;
    align-items: center;

    @media (@max-screen-sm) {
        bottom: 5rem;
        left: 5rem;
    }
}

.home-sustainability-textbox {
    background: #ffffff;
    box-sizing: border-box;
    padding: 0 1rem;
    position: absolute;
    bottom: 5rem;
    right: 10rem;
    font-family: @roboto;
    overflow: hidden;
    display: flex;
    align-items: center;

    @media (@max-screen-sm) {
        bottom: 5rem;
        right: 5rem;
    }
}

.other-product-list > * {
    max-width: calc(50% - 2rem);
    @media (@min-screen-sm) {
        max-width: calc(35% - 2rem);
    }
}

.news-home {
    input::placeholder {
        color: @color-default-5;
        font-weight: bold;
        text-align: left;
    }

    .form-news-home {
        line-height: 2.8rem;
    }

    .form-news {
        margin: 0 auto;
        width: 70%;

        strong {
            float: left;
        }
    }

    input {
        font-size: 16px;
    }
}

.hover-accordion {
    height: 42rem;
    display: flex;
    gap: 2rem;
}

.hover-accordion-expand-first {
    &:not(:hover) .accordion-item-wrapper:first-child {
        flex-grow: 5;
    }

    .accordion-item-wrapper:hover {
        flex-grow: 5;
    }
}
.accordion-item-wrapper {
    position: relative;
}

.home-stores-wrapper {
    font: @font-proxima-nova;
}

.store-description-wrapper {
    position: absolute;
    width: auto;
    min-width: 240px;
    opacity: 1;
    background-color: #201f1e;
    color: white;
    z-index: 9;
    padding: 0.5rem;
    gap: 0.5rem;
    display: none;
}

.store-text-wrapper {
    position: absolute;
    width: 11rem;
    right: 0;
    bottom: 0;
    opacity: 1;
    background-color: #201f1e;
    color: white;
    z-index: 9;
    padding: 0.5rem;
    gap: 0.5rem;
    text-align: left;
    display: none;
}

.store-more-info {
    position: absolute;
    right: 0;
    bottom: 1.5rem;
    padding: 1rem;
    background-color: @color-white;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
}

.store-more-info-opened {
    position: absolute;
    right: 0;
    top: 0;
    padding: 1rem;
}

.store-text-wrapper-mobile {
    position: absolute;
    top: 0;
    place-content: center;
    display: none;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0.5rem;
}

#toggle-bh:checked ~ .store-text-wrapper-mobile {
    display: flex;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(7.5px);

    @media (max-width: 400px) {
        display: block;
    }
}

#toggle-jf:checked ~ .store-text-wrapper-mobile {
    display: flex;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(7.5px);
    @media (max-width: 400px) {
        display: block;
    }
}

#toggle-tiradentes:checked ~ .store-text-wrapper-mobile {
    display: flex;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(7.5px);
    @media (max-width: 400px) {
        display: block;
    }
}

.keep-state .store-text-wrapper {
    display: block;
}

.keep-state .store-description-wrapper {
    display: block;
}

// <editor-fold desc="Vapt Vupt Home">
//TODO: VAPT-Vupt da home
//.vvp{
//  @media(@max-screen-sm) {
//    padding-right: 0;
//  }
//}
//.vapt-vupt {
//  height: 337px;
//  @media(@min-screen-sm) and (@max-screen-lg)
//  {
//    height: 285px;
//  }
//  @media(@max-screen-sm) {
//    height: 100%;
//  }
//
//  h3 {
//    font-size: 1.0rem;
//    z-index: 3;
//    position: relative;
//    span{
//      font-size: 1.5rem;
//    }
//    @media(@max-screen-sm) {
//      font-size: 1rem;
//      span{
//        font-size: 1.5rem;
//      }
//    }
//  }
//}

//.vapt-vupt-texts {
//  position: relative;
//  @media(@max-screen-sm) {
//    display: flex;
//  }
//}
//
//.full-vapt{
//  width: 100%;
//  background-color: #F7F6F5;
//}
//.vapt-vupt-img {
//  position: absolute;
//  left: 50%;
//  top: -30px;
//  z-index: 2;
//  @media(@max-screen-sm) {
//    position: relative;
//    width: 100%;
//    height: 100%;
//    left: 0;
//    top: 0;
//    padding-top: 2rem;
//    img{
//      float: right;
//    }
//  }
//  @media(@min-screen-sm) and (@max-screen-lg)
//  {
//    top: 30px;
//    right: 2px;
//    left: initial;
//    padding: 0;
//    margin: 0;
//    max-width: 32%;
//    img{
//      max-width: 100%;
//      /* top: 30px; */
//      float: right;
//    }
//  }
//
//}

//.vapt-vupt-selectors {
//  display: inline-flex;
//  flex-direction: column;
//  align-items: flex-start;
//  gap: 0.5rem;
//
//  @media(@max-screen-sm) {
//    width: 100%;
//    >div {
//      display: block !important;
//    }
//  }
//
//  .btn:hover {
//    font-weight: bold !important;
//    border: 3px solid;
//  }
//
//  .btn {
//    width: 3.3rem;
//    height: 2.188rem;
//    padding: 8px 10px;
//    font-size: 1rem;
//    line-height: 2rem;
//    display: inline-flex;
//    justify-content: center;
//    align-items: center;
//    @media(@max-screen-sm) {
//      width: 22%;
//      height: 3rem;
//      margin: 0 4px 10px 0;
//    }
//  }
//
//  h5 {
//    font-family: @font-proxima-nova;
//    font-size: 16px;
//    font-style: normal;
//    font-weight: 700;
//    line-height: normal;
//    letter-spacing: 0.16px;
//    width: 6rem;
//    padding-bottom: 5px;
//    @media(@max-screen-sm) {
//      width: 100%;
//    }
//  }
//
//  .sizes-line {
//    @media(@max-screen-sm) {
//      width: 100%;
//    }
//    &:hover {
//      h5 {
//        border-bottom: 3px solid @color-black;
//        padding-bottom: 2px !important;
//      }
//
//      a:hover {
//        text-decoration: none;
//      }
//    }
//  }
//}
// </editor-fold>